<!-- 获奖名单 -->
<!--
 * 
 *邀请有奖
-->

<template>
  <div>
    <div id="winnerList">
      <!-- 固定顶部 -->
      <div class="fixedBox" v-if="activityId == 1">
        <!-- tabs -->
        <div class="tabs">
          <div
            v-for="item in tabs"
            :key="item.value"
            :class="item.value == activeTab ? 'activeTab' : ''"
            @click="listTabs(item.value)"
          >
            {{ item.name }}

            <div v-if="item.value == activeTab" class="tabLine"></div>
          </div>
        </div>
      </div>

      <div
        v-if="activeTab === 1 && activityId == 1"
        class="commonList"
        :style="activityId == 2 && isApp ? 'padding-top:20px;' : ''"
      >
        <div class="title">成功邀请3人，前100名用户名单</div>
        <div class="desc">恭喜获得1次9.9元《仙武帝尊》系列购买资格</div>

        <div class="nameList">
          <span v-for="(item, index) in tab1Name" :key="index">{{ item.user_name }}</span>
        </div>
      </div>

      <div
        v-if="activeTab === 2 || activityId == 2"
        class="commonList"
        :style="activityId == 2 && isApp ? 'padding-top:20px;' : ''"
      >
        <div class="title">邀请榜前{{ activityId == 1 ? '10' : '100' }}名用户名单</div>
        <div class="desc">
          {{
            activityId == 1
              ? '恭喜获得《仙武帝尊》系列盲盒空投一份，5个工作日内完成空投'
              : '恭喜获得《仙武帝尊》系列的一份指定藏品和一份盲盒空投，5个工作日内完成空投'
          }}
        </div>
        <div v-if="activeTab == 2 || activityId == 2" class="rankBox">
          <div class="rankList">
            <div class="rankItem" v-for="(item, index) in tab2Name" :key="index">
              <div class="userInfo">
                <div class="number" :style="index < 3 ? 'background: #3CDBE8;color:black;' : ''">
                  {{ index + 1 }}
                </div>
                <div class="name">
                  <div :style="index < 3 ? 'color:#3CDBE8' : ''">{{ item.user_name }}</div>
                </div>
              </div>

              <div>
                邀请<span class="inviteNum">{{ item.num }}</span
                >人
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { getactivityuserlist } from '@/service/invite';
import { useRoute } from 'vue-router';
import { nftUtils } from '@/utils';

export default {
  name: 'winnerList',
  setup() {
    const route = useRoute();
    const state = reactive({
      tabs: [
        {
          value: 1,
          name: '名单一'
        },
        {
          value: 2,
          name: '名单二'
        }
      ],
      activeTab: 1,
      tab1Name: [],
      tab2Name: [],
      activityId: route.query.activityId,
      isApp: nftUtils.isInApp()
    });

    const listTabs = value => {
      state.activeTab = value;
    };

    const getList = () => {
      getactivityuserlist({
        activity_id: state.activityId
      }).then(res => {
        console.log(res);
        if (res.status == 0) {
          for (let i in res.data) {
            if (res.data[i].reward_type == 1) {
              state.tab1Name = res.data[i].users;
            } else if (res.data[i].reward_type == 2) {
              state.tab2Name = res.data[i].users;
            }
          }
        }
        // if(res.)
      });
    };

    onMounted(() => {
      getList();
    });

    return {
      ...toRefs(state),
      listTabs
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}
#winnerList {
  .fixedBox {
    position: fixed;
    background: $bc;
    width: 100%;
    top: 0;
    left: 0;
  }

  .tabs {
    display: flex;
    justify-content: center;
    line-height: 40px;
    padding: 7px 0;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);

    .activeTab {
      color: white;
      font-size: 16px;
      font-weight: 500;
    }

    div {
      padding: 0 18px;
      position: relative;

      .tabLine {
        height: 3px;
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
      }
    }
  }

  .commonList {
    padding: 0 20px 20px 20px;
    padding-top: 70px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }

    .desc {
      font-size: 12px;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.7);
      line-height: 26px;
      // white-space: nowrap;
    }

    .nameList {
      margin-top: 8px;

      span {
        display: inline-block;
        width: 50%;
        text-align: left;
        padding-bottom: 8px;
      }
    }

    .rankList {
      .rankItem {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 400;

        .userInfo {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .number {
          color: rgba(255, 255, 255, 1);
          width: 28px;
          height: 28px;
          background: rgb(54, 51, 64);
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          line-height: 28px;
          border-radius: 50%;
        }

        .myNumber {
          background: #fff;
          color: black;
        }

        .headImg {
          margin-left: 20px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid #3cdbe8;
            object-fit: cover;
          }
        }

        .name {
          margin-left: 16px;

          :first-child {
            font-size: 14px;
            font-weight: 600;
          }

          :last-child {
            color: rgba(255, 255, 255, 0.7);
          }
        }

        .inviteNum {
          color: #3cdbe8;
        }
      }
    }
  }
}
</style>
